import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

const aboutInfo = graphql`
  query {
    aboutPage: contentfulAboutPage {
      pageTitle
      image {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      authorNameEnglish
      authorNameJapanese
      richTextJapanese {
        json
      }
      richTextEnglish {
        json
      }
    }
  }
`

const About = ({ location }) => {
  const response = useStaticQuery(aboutInfo)

  const about = response.aboutPage

  const Text = ({ children }) => (
    <p>
      {children}
      <br />
    </p>
  )
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
    renderText: text => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  return (
    <Layout location={location}>
      <SEO title="About" />
      <AboutContainer>
        <PageTitle>{about.pageTitle}</PageTitle>
        <AuthorImg fluid={about.image.fluid} />
        <Author>
          <AuthorEn>{about.authorNameEnglish}</AuthorEn>
          <AuthorJp>{about.authorNameJapanese}</AuthorJp>
        </Author>
        <AboutText>
          <TextJp>
            {documentToReactComponents(about.richTextJapanese.json, options)}
          </TextJp>
          <TextEn>
            {documentToReactComponents(about.richTextEnglish.json, options)}
          </TextEn>
        </AboutText>
      </AboutContainer>
    </Layout>
  )
}

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  margin-top: 5vh;
`

const PageTitle = styled.h1`
  font-family: "Mukta", sans-serif;
  font-weight: 400;
  font-size: 3.5vh;
  text-transform: uppercase;
  margin: 3vh 0;
`

const AuthorImg = styled(Img)`
  max-width: 500px;
  width: 80vw;
  margin: 3vh 0;
`

const Author = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3vh 0;
  letter-spacing: 0;
`

const AuthorEn = styled.h2`
  font-family: "Mukta", sans-serif;
  font-weight: 400;
  font-size: 2.5vh;
`

const AuthorJp = styled.h2`
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  font-size: 2vh;
`

const AboutText = styled.div`
  max-width: 800px;
  width: 100%;
  letter-spacing: 0;
  margin: 3vh 0;
`

const TextJp = styled.p`
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 300;
  font-size: 1.8vh;
  line-height: 1.5;
  margin-bottom: 10vh;
`

const TextEn = styled.p`
  font-family: "Mukta", sans-serif;
  font-weight: 300;
  font-size: 2vh;
  line-height: 1.4;
  margin-bottom: 10vh;
`

export default About
